@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap");
.service-header {
  display: flex;
  padding: 16px 48px;
  box-shadow: 0 0 10px var(--main1);
  background-color: #ffffff;
}

.service-main {
  background-color: #f7f7f7;
  padding: 50px 16px;
}

.element-wrapper {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  gap: 20px;
}

.element-title {
  font-size: 20px;
  margin: 40px 0;
}

.element-sub-title {
  font-size: 16px;
  margin-bottom: 40px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 25px 0;
}

.flex-raw {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 25px 0;
}

.box-300 {
  width: 300px;
}

.link {
  width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  padding: 0 16px;
  border: solid 2px aqua;
  font-weight: bold;
  border-radius: 8px;
  text-transform: capitalize;
  color: var(--main0);
  white-space: nowrap;
}
.link:hover {
  background-color: aquamarine;
}

.section-wrapper {
  padding: 23px 30px;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  background-color: #ffffff;
}
@media screen and (max-width: 639px) {
  .section-wrapper {
    padding: 12px;
  }
}

.section-pre-wrapper {
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  background-color: #ffffff;
  padding-bottom: 20px;
}
.section-pre-wrapper .card-body {
  z-index: 2;
  position: relative;
}

.persona {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 20px;
  cursor: pointer;
  transition: 0.25s;
}
.persona:hover {
  background-color: #cef0ff;
}
.persona:hover .persona__name {
  color: #000000;
}

.persona__ava {
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
  overflow: hidden;
}

.persona__ava-img {
  display: block;
  max-height: 100%;
  margin: 0 auto;
}

.persona__details {
  flex: auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  overflow: hidden;
  color: #4e4e4e;
  gap: 12px;
}

.persona__details-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.persona__name {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.persona__sub {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 15px;
}

.persona__details-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.persona__time {
  font-size: 10px;
  line-height: 13px;
  white-space: nowrap;
}

.persona__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ea9210;
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
}

.card-pre__body {
  position: relative;
  border-radius: 10px;
  z-index: 1;
  padding: 20px 20px 0 20px;
}
.card-pre__body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 140%;
  background-color: #ffecc8;
  border-radius: 10px;
}

.logo {
  flex: auto;
  display: inline-flex;
  align-items: center;
  height: 26px;
}

.logo-img {
  overflow: hidden;
  height: 26px;
  margin: 0 auto;
}

.whatsup {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(/assets/icons/whatsapp.svg);
}

.telegram {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(/assets/icons/telegram.svg);
}

.chat {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(/assets/icons/chat.svg);
}

.note {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(/assets/icons/notification-bell.svg);
  position: relative;
}
.note span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ea9210;
  color: #ffffff;
  height: 15px;
  border-radius: 8px;
  font-size: 9px;
  padding: 0 4px;
  min-width: 15px;
  position: absolute;
  top: -4px;
  left: 100%;
  transform: translateX(-10px);
  outline: solid 1px #ffffff;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
}

.dash {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(/assets/icons/darhboard.svg);
}

.push-btn {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  cursor: pointer;
  transition: 0.25s;
  background-image: url(/assets/icons/u_arrow-growth.svg);
}
.push-btn:hover {
  opacity: 0.7;
}

.edit-btn {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  cursor: pointer;
  transition: 0.25s;
  background-image: url(/assets/icons/Edit.svg);
}
.edit-btn:hover {
  opacity: 0.7;
}

.delete-btn-white {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  cursor: pointer;
  transition: 0.25s;
  background-image: url(/assets/icons/delete-w.svg);
}
.delete-btn-white:hover {
  opacity: 0.7;
}

.delete-btn {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  cursor: pointer;
  transition: 0.25s;
  background-image: url(/assets/icons/delete-one.svg);
}
.delete-btn:hover {
  opacity: 0.7;
}

.favorit-btn {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  cursor: pointer;
  transition: 0.25s;
  background-image: url(/assets/icons/Star.svg);
}
.favorit-btn:hover {
  opacity: 0.7;
}
.favorit-btn:hover {
  background-image: url(/assets/icons/delete-w.svg);
}

.button-f {
  font-weight: 700;
  font-size: 17px;
  border-radius: 10px;
  height: 2.5em;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
  cursor: pointer;
  background-color: #2499cb;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .button-f {
    font-size: 13px;
  }
  .button-f.h--44 {
    font-size: 17px;
  }
}
.button-f:hover {
  background-color: #1a80ab;
}

.button-e {
  font-weight: 700;
  font-size: 17px;
  border-radius: 10px;
  height: 2.5em;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #dfdfdf;
  color: #4e4e4e;
}
@media screen and (max-width: 767px) {
  .button-e {
    font-size: 13px;
  }
  .button-e.h--44 {
    font-size: 17px;
  }
}
.button-e:hover {
  border-color: #2499cb;
  color: #2499cb;
}
.button-e.not--active {
  background-color: #dfdfdf;
  color: #ffffff;
  pointer-events: none;
}

.no-button {
  padding: 0 1em;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #a4a4a4;
  transition: 0.25s;
  cursor: pointer;
}
.no-button:hover {
  color: #4e4e4e;
}

.button-27e {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #4e4e4e;
  padding: 0 1em;
  min-height: 2em;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.25s;
}
.button-27e:hover {
  background-color: #2499cb;
  color: #ffffff;
  border-color: #2499cb;
  font-weight: 600;
}

.button-27f {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 0 1em;
  min-height: 2em;
  border-radius: 10px;
  border: 1px solid #2499cb;
  background-color: #2499cb;
  cursor: pointer;
  transition: 0.25s;
}
.button-27f:hover {
  background-color: #1a80ab;
  color: #ffffff;
  border-color: #1a80ab;
  font-weight: 600;
}

.button-40 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #4e4e4e;
  padding: 0 1em;
  min-height: 40px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  transition: 0.25s;
  cursor: pointer;
}
.button-40:hover {
  background-color: #dfdfdf;
  color: #000000;
}

.button-link-web {
  height: 36px;
  padding: 0 12px;
  background: #cef0ff;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.button-link-web span {
  display: block;
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
.button-link-web span {
  background-image: url(/assets/icons/Website.svg);
}

.button-link-doc {
  height: 36px;
  padding: 0 12px;
  background: #cef0ff;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.button-link-doc span {
  display: block;
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
.button-link-doc span {
  background-image: url(/assets/icons/document-b.svg);
}

.button-link-phone {
  height: 36px;
  padding: 0 12px;
  background: #cef0ff;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.button-link-phone span {
  display: block;
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
.button-link-phone span {
  background-image: url(/assets/icons/phone.svg);
}

.button-link-mail {
  height: 36px;
  padding: 0 12px;
  background: #cef0ff;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #a4a4a4;
}
.button-link-mail span {
  display: block;
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
.button-link-mail span {
  background-image: url(/assets/icons/mail.svg);
}

.button-link-play {
  height: 36px;
  padding: 0 12px;
  background: #cef0ff;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #a4a4a4;
}
.button-link-play span {
  display: block;
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
.button-link-play span {
  background-image: url(/assets/icons/play.svg);
}

@media screen and (max-width: 639px) {
  .button-27e {
    font-size: 12px;
  }
  .button-27f {
    font-size: 12px;
  }
}

.field-set {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.field-set2 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.field-set__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000000;
}
.field-set__title .checkbox-slide + label {
  font-size: 14px;
  font-weight: 400;
  color: #4e4e4e;
}
.field-set__title.flex--between {
  justify-content: space-between;
}

.fied-set__error {
  font-size: 14px;
  color: #ea9210;
}

.red-note {
  font-size: 12px;
  color: rgb(243, 68, 68);
}

.sub-field {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4e4e4e;
}

.select {
  width: 100%;
  height: 45px;
  background: transparent;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition: 0.25s;
}
.select::after {
  content: "";
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/icons/arr-down.svg);
  margin-right: 16px;
}
.select:focus-within {
  border-color: #2499cb;
}
.select select {
  flex: auto;
  width: 100%;
  height: 100%;
  padding: 0 0.7em;
  font-size: inherit;
  text-align: inherit;
  background-color: transparent;
}

.input {
  width: 100%;
  height: 45px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  transition: 0.25s;
}
.input:focus-within {
  border-color: #2499cb;
}
.input input {
  flex: auto;
  width: 100%;
  height: 100%;
  padding: 0 0.7em;
  font-size: inherit;
  text-align: inherit;
  background-color: transparent;
}

.input-search {
  width: 100%;
  height: 35px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: 0.25s;
}
.input-search:focus-within {
  border-color: #2499cb;
}
.input-search input {
  flex: auto;
  width: inherit;
  height: 100%;
  padding: 0 0.7em;
  font-size: inherit;
  text-align: inherit;
  background-color: transparent;
}
.input-search button {
  flex: 0 0 40px;
  height: 100%;
  background-image: url(/assets/icons/search.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.input-message {
  width: 100%;
  height: 40px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: 0.25s;
}
.input-message:focus-within {
  border-color: #2499cb;
}
.input-message input {
  flex: auto;
  width: inherit;
  height: 100%;
  padding: 0 0.7em;
  font-size: inherit;
  text-align: inherit;
  background-color: transparent;
}
.input-message button {
  flex: 0 0 40px;
  height: 100%;
  background-image: url(/assets/icons/Send_hor.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.input-date {
  width: 100%;
  height: 45px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: 0.25s;
  position: relative;
  font-size: 16px;
}
.input-date ::-webkit-calendar-picker-indicator {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/calendar.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}
.input-date:focus-within {
  border-color: #2499cb;
}
.input-date input {
  flex: auto;
  width: 100%;
  height: 100%;
  padding: 0 0.7em;
  font-size: inherit;
  text-align: inherit;
  background-color: transparent;
}

.textarea {
  min-width: 100%;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  padding: 12px;
  resize: vertical;
}
.textarea:focus, .textarea:focus-within, .textarea:focus-visible {
  border-color: #2499cb;
  outline: none;
}
.textarea textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  resize: none;
}

.class--error {
  border-color: #ea9210;
}

.checkbox {
  display: none;
  opacity: 0;
  position: absolute;
}
.checkbox + label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #4e4e4e;
  cursor: pointer;
}
.checkbox + label::before {
  content: "";
  display: block;
  width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #dfdfdf;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #dfdfdf;
}
.checkbox:checked + label::before {
  background-color: #2499cb;
}
.checkbox + label::before {
  background-image: url(/assets/icons/check.svg);
}

.checkbox-e {
  display: none;
  opacity: 0;
  position: absolute;
}
.checkbox-e + label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #4e4e4e;
  cursor: pointer;
}
.checkbox-e + label::before {
  content: "";
  display: block;
  width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #dfdfdf;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #dfdfdf;
}
.checkbox-e:checked + label::before {
  background-color: #2499cb;
}
.checkbox-e + label.to--black {
  color: #000000;
}
.checkbox-e + label::before {
  background-color: #f2f2f2;
  border: 1px solid #dfdfdf;
}
.checkbox-e:checked + label::before {
  border-color: #2499cb;
  background-image: url(/assets/icons/check.svg);
}

.main-foto {
  display: none;
  position: absolute;
  opacity: 0;
  left: -2000px;
}
.main-foto + label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4e4e4e;
  display: flex;
  align-items: center;
}
.main-foto + label::before {
  content: "";
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/icons/Star.svg);
  background-size: 20px;
  margin-right: 6px;
}
.main-foto:checked + label::before {
  background-image: url(/assets/icons/Star-wh.svg);
}

.checkbox-slide {
  display: none;
  opacity: 0;
  position: absolute;
}
.checkbox-slide + label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;
}
.checkbox-slide + label span {
  width: 44px;
  flex: 0 0 44px;
  height: 24px;
  border-radius: 12px;
  background-color: #dfdfdf;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.25s;
  padding: 0 3px;
}
.checkbox-slide + label span::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ffffff;
}
.checkbox-slide:checked + label span {
  justify-content: flex-end;
  background-color: #2499cb;
}

.from-who-box {
  display: inline-flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  padding: 2px;
  gap: 2px;
}
.from-who-box span {
  display: block;
  width: 1px;
  height: 15px;
  background-color: #a4a4a4;
}

.from-who {
  display: none;
  opacity: 0;
  position: absolute;
}
.from-who + label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: 32px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #4e4e4e;
  padding: 0 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.25s;
}
.from-who + label:hover {
  background: #f2f2f2;
}
.from-who:checked + label {
  background: #cef0ff;
  color: #000000;
}

.radio-balls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-string {
  display: flex;
  gap: 20px;
}

.radio-ball {
  display: none;
  opacity: 0;
  position: absolute;
}
.radio-ball + label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 15px;
  color: #4e4e4e;
  cursor: pointer;
}
.radio-ball + label span {
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #dfdfdf;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
  padding: 0 3px;
}
.radio-ball + label span::before {
  content: "";
  display: block;
  flex: 0 0 18px;
  height: 18px;
  border-radius: 50%;
  background-color: transparent;
}
.radio-ball:checked + label span {
  border-color: #2499cb;
}
.radio-ball:checked + label span::before {
  background-color: #2499cb;
}

.input-upload {
  max-width: 1120px;
  height: 90px;
  border-radius: 10px;
  border: dashed 1px #a4a4a4;
  background-color: #f2f2f2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.input-upload input {
  width: 100%;
  height: 100%;
  border: 0;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.input-upload label {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  min-width: 180px;
  cursor: pointer;
  z-index: 1;
}
@media screen and (max-width: 539px) {
  .input-upload .upload__img {
    display: none;
  }
  .input-upload label {
    font-size: 12px;
    min-width: unset;
    white-space: nowrap;
  }
  .input-upload .slide-flex {
    flex-direction: column;
    align-items: flex-start;
  }
}

.upload-data {
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 370px;
}
.upload-data::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(/assets/icons/dddown.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.upload__info {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4e4e4e;
}

.upload__sub {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #828282;
}

.box-335 {
  width: 100%;
  max-width: 335px;
  margin: 20px 0;
}

.logo-upload {
  width: 100%;
  background: #f2f2f2;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 18px;
}

.uploat__title {
  display: flex;
  align-items: center;
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #4e4e4e;
}
.uploat__title::before {
  content: "";
  display: block;
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #ffffff;
  background-image: url(/assets/images/upload-im.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  margin-right: 14px;
}

@media screen and (max-width: 539px) {
  .from-who-box {
    border-radius: 5px;
  }
  .from-who + label {
    height: 22px;
    border-radius: 5px;
    font-size: 12px;
    padding: 0 8px;
  }
}