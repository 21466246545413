.bid-flex {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 40px;
  flex-wrap: wrap;
}

.bid__general {
  flex: 0 0 60%;
}

.bid__txt {
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  padding: 16px 0 26px;
}

.bid__addons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
}

.bid__details {
  flex: 0 0 35%;
  min-width: 380px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  padding-bottom: 30px;
}

.bid__details-item {
  padding: 15px 20px;
  border-bottom: 0.5px solid #DFDFDF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bid__details-item__data {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.bid__details-item__value {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.bid__controls {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px 6px;
}

.bid__date {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #A4A4A4;
  margin-top: 10px;
}
.bid__date time {
  color: #000000;
}

.replies {
  flex: 0 0 100%;
}

.reply__title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.reply__count {
  font-weight: 300;
  color: #4E4E4E;
  font-weight: 400;
}

.reply__count-new {
  font-weight: 300;
  color: #EA9210;
}

.replies-box {
  padding: 30px 0;
}

.reply-item {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  padding: 20px 30px;
  border: 1px solid transparent;
  margin-bottom: 10px;
}
.reply-item.reply--sleep {
  opacity: 0.5;
}
.reply-item.reply--sleep button {
  pointer-events: none;
  cursor: none;
}
.reply-item:hover {
  border: 1px solid #EA9210;
}
.reply-item.reply-item--active {
  border-color: #3FBE62;
}

.reply-item__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.reply__holder {
  display: flex;
  align-items: center;
}

.reply__holder-logo {
  flex: 0 0 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}

.reply__holder-name {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

.reply__time {
  display: flex;
  align-items: center;
  gap: 26px;
}

.reply-new {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #EA9210;
}

.performer {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #3FBE62;
}

.reply-date {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #A4A4A4;
  white-space: nowrap;
}

.reply-item__mid {
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  padding: 8px 0 22px;
}

.reply-item__bot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reply-idents {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.reply__ident {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.reply__ident span {
  color: #A4A4A4;
}
.reply__ident::before {
  content: "";
  display: block;
  width: 24px;
  flex: 0 0 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 4px;
}

.ident__money::before {
  background-image: url(/assets/icons/money-offer.svg);
}

.ident__time::before {
  background-image: url(/assets/icons/time.svg);
}

.reply-controls {
  display: flex;
  align-items: center;
  gap: 8px 6px;
}

@media screen and (max-width: 1023px) {
  .bid-flex {
    flex-direction: column;
    align-items: center;
  }
  .bid__details {
    order: 2;
    flex: auto;
    width: 100%;
    max-width: 410px;
    min-width: 0;
  }
  .replies-box {
    padding-bottom: 0;
  }
  .reply__title {
    font-size: 20px;
    line-height: 24px;
  }
  .reply-item {
    padding: 16px;
  }
  .reply-item__top {
    flex-direction: column;
    align-items: flex-start;
  }
  .reply-new {
    order: 1;
  }
  .performer {
    order: 1;
  }
  .reply__ident {
    font-size: 17px;
    line-height: 20px;
  }
  .reply__ident::before {
    width: 20px;
    flex: 0 0 20px;
    height: 20px;
  }
  .reply-item__bot {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .reply-controls .no-button {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    font-size: 0;
    line-height: 0;
    color: transparent;
    background-image: url(/assets/icons/chat.svg);
    order: 1;
  }
  .reply-controls .button-27f {
    order: 2;
  }
}