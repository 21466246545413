.politic__inner {
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 34px 30px;
}

.politic-head__title {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 40px;
}

.politic-title {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.politic--stong {
  font-weight: 700;
}

.politic__list {
  list-style: disc;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.politic__item {
  padding-top: 8px;
  padding-bottom: 8px;
}

.politic__item-text {
  font-size: 15px;
  line-height: 19px;
  color: #4E4E4E;
  padding-top: 8px;
  padding-bottom: 8px;
}
.politic__item-text a {
  color: #2499CB;
  text-decoration: underline;
}

.list-number {
  padding-top: 8px;
  padding-bottom: 8px;
  counter-reset: item;
  list-style: none;
}
.list-number .list-number {
  padding-left: 10px;
}

.list-number__item {
  margin-bottom: 16px;
}
.list-number__item:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

@media screen and (max-width: 1180px) {
  .politic__inner {
    padding: 15px;
  }
  .politic-head__title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .politic-title {
    font-size: 20px;
    line-height: 26px;
  }
}