.chat-container {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  width: 100%;
  height: 652px;
  overflow: hidden;
  position: relative;
}

.chat-sidebar {
  flex: 0 0 345px;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-right: 0.5px solid #DFDFDF;
  overflow: hidden;
}

.chat-sidebar__inner {
  padding: 0 20px;
}

.chat-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

.search-chat {
  padding: 10px 0;
}

.chat-items {
  flex: auto;
  overflow: auto;
}

.chat-body {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-body__header {
  height: 55px;
  min-height: 55px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.whos-chatting {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
}

.whos__logo__box {
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whos__img {
  max-height: 100%;
}

.whos__data {
  overflow: hidden;
}

.whos__name {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.was__here {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #A4A4A4;
}

.chat-body__body {
  flex: auto;
  background-image: url(/assets/images/chat-bg.png);
  padding: 16px 30px;
  overflow: auto;
}

.chat-day {
  padding-bottom: 7px;
}

.chat-day__date {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
}

.message__item {
  display: flex;
  margin-bottom: 3px;
}
.message__item.mine {
  justify-content: flex-end;
}

.mine .message {
  background: #CEF0FF;
  border-radius: 10px 10px 0px 10px;
}

.message {
  max-width: 90%;
  display: inline-flex;
  padding: 11px 14px;
  background: #FFFFFF;
  flex-direction: column;
  border-radius: 10px 10px 10px 0px;
}

.message__txt {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}

.message__time {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #A4A4A4;
}

.chat-body__message {
  height: 60px;
  min-height: 60px;
  padding: 10px;
  display: flex;
  gap: 5px;
}

.attach__button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-color: #F2F2F2;
  border-radius: 10px;
  background-image: url(/assets/icons/Paperclip.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.chat-banners {
  width: 100%;
  display: flex;
  gap: 22px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding-top: 40px;
}
.chat-banners img {
  width: 100%;
}

.banner__item {
  flex: 1;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 839px) {
  .chat-sidebar {
    flex: 0 0 100%;
    border-color: transparent;
  }
  .chat-body {
    display: none;
  }
  .chat-body.is--visible {
    display: flex;
    background-color: #ffffff;
    position: absolute;
    inset: 0;
  }
  .message {
    max-width: unset;
  }
  .chat-banners {
    flex-direction: column;
    justify-content: flex-start;
  }
}