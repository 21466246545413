body {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

select {
  border: none;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
}
select:focus, select:focus-within, select:focus-visible {
  border: none;
  outline: none;
}

select::-ms-expand {
  display: none;
}

input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
}
input:focus, input:focus-within, input:focus-visible {
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=file] {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: inherit;
}