.nav {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DFDFDF;
}

.nav__box {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}

.burger {
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.burger::before, .burger::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #4E4E4E;
  border-radius: 2px;
  transition: 0.25s;
}
.burger span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #4E4E4E;
  border-radius: 2px;
  transition: 0.25s;
}
.burger:hover::before, .burger:hover::after,
.burger:hover span, .burger:focus::before, .burger:focus::after,
.burger:focus span {
  background-color: #2499cb;
}

.portal-about {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E4E4E;
}

.user-box {
  display: flex;
  align-items: center;
  gap: 37px;
  position: relative;
}

.auths {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4E4E4E;
}

.header-notiffication__box {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-avatar__box {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.avatar__box {
  width: 35px;
  flex: 0 0 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  max-width: 100%;
  max-height: 100%;
}

.user__nic {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-transform: capitalize;
}

@media screen and (max-width: 767px) {
  .portal-about {
    display: none;
  }
  .user-box {
    gap: 20px;
  }
  .user__nic {
    display: none;
  }
}