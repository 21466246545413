.list-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 8px;
}

.list-controls__switch {
  display: flex;
  align-items: center;
  gap: 24px;
}

.list-controls__sort {
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.sort-box {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: inherit;
}
.sort-box label {
  color: #a4a4a4;
  font-size: inherit;
}
.sort-box select {
  font-size: inherit;
  font-weight: bold;
  cursor: pointer;
}
.sort-box span {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/icons/sort-arr.svg);
}

.result-box {
  font-size: inherit;
  color: #a4a4a4;
}

.result-counter {
  color: #2499cb;
}

.list-base {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.list-filters {
  flex: 0 0 280px;
  width: 280px;
}

.list-ads {
  flex: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filters {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  padding: 0px 0 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4e4e4e;
}

.filter-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  padding: 0 20px;
}

.search-box {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 10px 20px;
}

.filter-button {
  display: none;
}

.back-filters {
  display: none;
}

.filter-counter {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ea9210;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  color: #ffffff;
  top: -2px;
  right: -2px;
}

.filter__list-item {
  position: relative;
  padding: 10px 20px;
}
.filter__list-item + .filter__list-item {
  border-top: solid 1px #dfdfdf;
}

.filter-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.filter-item .filter-item__name {
  text-align: left;
}
.filter-item .filter-item__mark {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #a4a4a4;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.filter-item .filter-item__mark::after {
  content: "";
  display: block;
  width: 8px;
  flex: 0 0 8px;
  height: 14px;
  background-image: url(/assets/icons/menu-arr.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.range-price {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.track-container {
  position: relative;
  padding: 8px 0;
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}

.slider-track {
  width: 100%;
  height: 4px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}

input[type=range]::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}

input[type=range]::-ms-track {
  appearance: none;
  height: 5px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background-color: #2499cb;
  cursor: pointer;
  margin-top: -8px;
  pointer-events: auto;
  border-radius: 50%;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #2499cb;
  pointer-events: auto;
  border: none;
}

input[type=range]::-ms-thumb {
  appearance: none;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #2499cb;
  pointer-events: auto;
}

input[type=range]:active::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 1px solid #2499cb;
}

.filter-type__title {
  margin-bottom: 10px;
}

.filter-slide label {
  font-weight: 400;
  justify-content: space-between;
}
.filter-slide label span {
  order: 1;
}

.filter-range .input-range {
  display: block;
  background-color: #dfdfdf;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  margin: 0 auto;
  outline: 0;
  margin: 20px 0;
}
.filter-range .input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #2499cb;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.range-box {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.range-box .input {
  height: 38px;
}

.filter-selected {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.filter-selected__item {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  height: 28px;
  background-color: #cef0ff;
  border-radius: 10px;
  padding: 0 35px 0 15px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #000000;
  white-space: nowrap;
}
.filter-selected__item::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 10px;
  width: 10px;
  height: 10px;
  background-image: url(/assets/icons/close-blue.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.filter-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding-top: 32px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 4px;
}
.filter-controls button {
  flex: auto;
}

.clear-button {
  color: #a4a4a4;
  text-decoration: underline;
  transition: 0.25s;
}
.clear-button:hover {
  color: #000000;
}

.side-banners {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-banner {
  margin-top: 20px;
}
.filter-banner img {
  width: 100%;
}

.side-banner {
  position: relative;
}

.side-banner-one {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.side-banner__image {
  width: 100%;
}

.the-name {
  text-transform: uppercase;
  color: #2499cb;
}
.the-name:after {
  content: "a";
  color: #ef950d;
}

.fz--18 {
  font-size: 18px;
}

.the-red {
  font-weight: bold;
  color: #f40c0c;
}
.the-red.fz--32 {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
}

.side-banner__link {
  position: absolute;
  inset: 0;
}

.screw-txt {
  transform: matrix(0.93, -0.36, 0.35, 0.94, 0, 0);
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
}

.the-blue {
  color: #2499cb;
}

.bottom-txt {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

.list-map {
  width: 100%;
  height: 100vh;
  max-height: 0;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.5s;
  background-color: rgb(126, 126, 126);
  margin-top: -10px;
}
.list-map.list-map--open {
  max-height: 580px;
  margin-top: 0;
}

.list-add {
  width: 100%;
  background-color: #ffffff;
  padding: 3px;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: stretch;
}
.list-add.mobile {
  display: none;
}

.list-add__media {
  flex: 0 0 180px;
  width: 180px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.list-add__image-box {
  width: 100%;
  flex: 0 0 160px;
  border-radius: 10px;
  overflow: hidden;
}

.list-add__image {
  height: 100%;
  width: auto;
  display: block;
  margin: 0 auto;
}

.list-add__sub {
  width: 100%;
  flex: 0 0 50px;
  align-items: center;
  background: #f8f8f8;
  border-radius: 10px;
}

.rate-container {
  height: 100%;
}
.rate-container img {
  display: none;
}

.rate-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.rate__stars {
  flex: 0 0 80px;
  width: 80px;
  display: flex;
  align-items: center;
}

.rate__number {
  font-weight: 500;
  font-size: 15px;
  color: #ea9210;
}

.rate__amo {
  font-weight: 400;
  font-size: 13px;
  color: #a4a4a4;
}

.company-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 0 8px;
  text-align: left;
}

.company-image__box {
  flex: 0 0 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-image__box img {
  max-width: 100%;
  max-height: 100%;
}

.list-add__content {
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 0 20px;
  overflow: hidden;
}

.list-add__content-details {
  width: 100%;
  flex: 0 0 160px;
  overflow: hidden;
  padding: 8px 0 8px 0;
}

.list-add__data-details {
  width: 100%;
  flex: 0 0 120px;
  overflow: hidden;
  padding-top: 16px;
}

.desctop-title-box {
  display: flex;
  align-items: center;
  gap: 8px;
}
.desctop-title-box img {
  display: block;
  width: 35px;
  flex: 0 0 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.list-add__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-add-txt {
  padding: 7px 0;
}

.list-add__controls {
  width: 100%;
  flex: 0 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 50px;
}

.list-add__adons {
  width: 100%;
  flex: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  padding-bottom: 8px;
}

.list-add__price {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  gap: 0 15px;
  flex-wrap: wrap;
}
.list-add__price.for--sale {
  color: #ea9210;
  font-weight: 500;
}

.list-add__sity {
  flex: 0 0 120px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.list-add__sity::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  flex: 0 0 22px;
  background-image: url(/assets/icons/map.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.list-add__date {
  color: #979797;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
}
.list-add__date time {
  color: #4e4e4e;
}

.list-add__company {
  flex: 0 0 140px;
}

.scan {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

.scan__data {
  font-size: 1.15em;
  line-height: 19px;
  font-weight: 500;
}

.scan__is {
  color: #3fbe62;
  margin-right: 15px;
  font-weight: normal;
}

.list-add__buttons {
  flex: auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.colbut {
  font-weight: 500;
  font-size: 12px;
  text-decoration-line: underline;
  color: #979797;
}

.mobile-title-box {
  display: none;
}

@media screen and (max-width: 1180px) {
  .list-base {
    flex-direction: column;
  }
  .filters {
    background-color: transparent;
    box-shadow: none;
  }
  .filters .input-search {
    background-color: #ffffff;
  }
  .filters .filter-title {
    display: none;
  }
  .list-filters {
    width: 100%;
    flex: auto;
  }
  .filter-button {
    display: block;
    flex: 0 0 50px;
    height: 35px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
    border-radius: 10px;
    background-image: url(/assets/icons/filters.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .back-filters {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    color: #000000;
    gap: 10px;
    padding: 20px;
    border-bottom: solid 1px #dfdfdf;
  }
  .back-filters::before {
    content: "";
    flex: 0 0 24px;
    height: 24px;
    background-image: url(/assets/icons/arrow-left-s-line.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .filter-cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: hsla(0, 0%, 0%, 0.55);
    display: none;
  }
  .filter-cover.is--visible {
    display: block;
  }
  .filter-wrap {
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .rest-filters {
    flex: auto;
    overflow: auto;
  }
  .filter-banner {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .list-add__sub {
    flex: 0 0 100px;
  }
  .list-add__controls {
    flex: 0 0 100px;
    flex-direction: column;
  }
  .list-add__buttons {
    width: 100%;
    justify-content: flex-start;
  }
  .list-add__sity {
    justify-content: flex-end;
  }
  .location-container-2 {
    flex: auto;
  }
  .list-add__buttons {
    gap: 4px;
  }
  .contact-modify,
  .chat-modify {
    width: 36px;
    height: 36px;
    font-size: 0px;
    line-height: 0px;
    color: transparent;
    border: none;
    min-width: 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
  }
  .contact-modify {
    background-image: url(/assets/icons/contacty.svg);
  }
  .chat-modify {
    background-image: url(/assets/icons/chaty.svg);
  }
}
@media screen and (max-width: 767px) {
  .list-base {
    gap: 0;
  }
  .search-box {
    padding: 0;
  }
  .list-add {
    flex-direction: column;
    gap: 0;
  }
  .list-add.desctop {
    display: none;
  }
  .list-add.mobile {
    display: flex;
  }
  .list-add__media {
    width: 100%;
    flex-direction: row;
    flex: auto;
    align-items: center;
    gap: 14px;
  }
  .list-add__image-box {
    flex: 0 0 123px;
    height: 110px;
  }
  .list-add__sub {
    flex: auto;
    background-color: transparent;
    padding: 0 8px;
  }
  .list-add__sub .rate-box {
    justify-content: flex-start;
  }
  .list-add__content {
    padding: 0 16px;
  }
  .rate-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .rate-container img {
    display: block;
    width: 35px;
    flex: 0 0 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
  }
  .list-add__content-details,
  .list-add__data-details {
    flex: auto;
  }
  .desctop-title-box {
    display: none;
  }
  .list-add__title {
    white-space: normal;
  }
  .list-add__buttons {
    justify-content: space-between;
    border-top: solid 1px #dfdfdf;
  }
  .scan {
    font-size: 12px;
  }
  .list-add__price {
    flex-direction: column;
    align-items: flex-start;
    font-size: 17px;
    line-height: 20px;
  }
  .mobile-title-box {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
  }
  .mobile-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
}