.add-title {
  font-weight: 600;
  font-size: 40px;
  color: #000000;
  margin-bottom: 20px;
}

.respond-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.add__header {
  display: none;
  overflow: hidden;
}

.add__footer {
  display: none;
}

.add__main,
.respond__main {
  flex: auto;
}

.top-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.top-flex__title {
  flex: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.top-flex__serv {
  flex: 0 0 463px;
}

.user-type-marker {
  font-weight: 600;
  font-size: 13px;
  height: 36px;
  padding: 0 12px;
  background: #ffffff;
  color: #1a80ab;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.add {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.add__form,
.respond__form {
  flex: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  min-height: 200px;
}
.add__form .add__footer,
.respond__form .add__footer {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.add__description,
.respond__description {
  flex: 0 0 278px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 25px 30px;
  min-height: 200px;
}
.add__description .add__header,
.respond__description .add__header {
  display: flex;
}
.add__description.data__info,
.respond__description.data__info {
  flex: 0 0 463px;
}

.add__header-title {
  font-weight: 500;
  font-size: 22px;
  color: #000000;
}

.how {
  display: none;
}

.new-add-form {
  width: 100%;
  padding-bottom: 16px;
}

.field-flex {
  display: flex;
  gap: 15px;
  padding-bottom: 18px;
}

.fiels-flex__item {
  flex: 1;
}
.fiels-flex__item.flex--small {
  flex: 0 0 210px;
}

.split-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.h--90 {
  height: 90px;
}

.upload-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.gide__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 15px;
}

.gide-list {
  list-style: auto;
  padding-left: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  margin-top: 20px;
}

.preview-flex {
  display: flex;
  gap: 10%;
}

.preview-flex-cont {
  display: flex;
  gap: 10%;
  padding-top: 24px;
}

.view__details {
  flex: auto;
}

.view--adons {
  max-width: 369px;
  margin-top: 30px;
  margin-bottom: 22px;
}

.view__serv {
  flex: auto;
}
.view__serv .feedback__item {
  border-bottom: none;
}

.preview-logo {
  filter: drop-shadow(0px 2px 4px rgba(209, 209, 209, 0.25));
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}

.view__media {
  flex: 0 0 411px;
  width: 411px;
  overflow: hidden;
}

.view__controls-inner {
  flex: 0 0 411px;
  width: 411px;
}

.view__media-inner {
  width: 100%;
  height: 400px;
}

.view__media-logo {
  width: 100%;
  height: min-content;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.view__media-logo::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 88.89%;
}
.view__media-logo img {
  display: block;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.inner-img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.view-price {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  padding: 20px 0;
}

.price--modify {
  font-weight: 600;
  color: #ea9210;
}

.view-add-controls {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.view-chat {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.view-chat__flex {
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
}

.view-chat__flex-item {
  flex: 0 0 180px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #f8f8f8;
  font-family: "PT Root UI", sans-serif;
}

.view-chat__company {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 0 8px;
}

.view-chat__company-img {
  max-width: 33px;
  min-width: 33px;
  max-height: 33px;
  border-radius: 50%;
}

.view-chat__date {
  padding: 0 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
}

.view-chat__date-title {
  color: #a4a4a4;
}

.view-chat__date-add {
  color: #4e4e4e;
}

.view-chat__ctrl {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 12px;
  text-decoration: underline;
}

.view-chat__show {
  color: #4e4e4e;
}

.view-chat__complain {
  color: #a4a4a4;
}

.valid-date {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #a4a4a4;
  display: flex;
  gap: 8px;
}
.valid-date time {
  color: #2499cb;
}

.view-add__flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.slide-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.upload-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px 2px;
  padding-top: 6px;
}

.upload-bar {
  width: 85%;
  height: 2px;
  background-color: #dfdfdf;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  bottom: -1px;
  transform: translateX(-50%);
}

.upload__indicator {
  height: 100%;
  background-color: #2499cb;
  border-radius: 2px;
}

.upload-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  height: 34px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  position: relative;
}
.upload-item.uploading {
  background-color: #f8f8f8;
}
.upload-item.uploading .upload-bar {
  display: block;
}
.upload-item.uploaded {
  background-color: #cef0ff;
}
.upload-item.uploaded .upload-bar {
  display: none;
}

.unupload {
  width: 12px;
  min-width: 12px;
  height: 12px;
  background-image: url(/assets/icons/close-small.svg);
  background-repeat: no-repeat;
  padding: 0;
}

.title-flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location.location--clear {
  margin-top: -12px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1180px) {
  .add-title {
    display: none;
  }
  .add__header {
    display: flex;
    padding: 0 15px;
    align-items: center;
    height: 50px;
    flex: 0 0 50px;
    position: relative;
  }
  .add__header-title {
    font-size: 16px;
    padding-left: 30px;
  }
  .add__header-btn {
    width: 100%;
    min-width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center left;
    background-image: url(/assets/icons/arrow-left-s-line.svg);
    position: absolute;
  }
  .add__main {
    padding: 0 20px;
    background-color: #f8f8f8;
  }
  .add__footer {
    display: flex;
    justify-content: center;
    flex: 0 0 67px;
    height: 67px;
    border-top: 1px solid #dfdfdf;
    padding: 5px 15px !important;
    background-color: #ffffff;
  }
  .add__footer .too--wide {
    width: 100%;
    max-width: 335px;
  }
  .top-flex__serv {
    display: none;
  }
  .add__form {
    position: fixed;
    inset: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0;
    flex: auto;
    max-width: 920px;
    margin: 0 auto;
    overflow: auto;
    padding-top: 70px;
  }
  .add__description {
    position: fixed;
    inset: 0;
    padding: 0;
    justify-content: space-between;
    border-radius: 0;
    flex: auto;
    max-width: 920px;
    margin: 0 auto;
    overflow: auto;
    z-index: 2;
  }
  .respond__description {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    justify-content: center;
    align-items: stretch;
    z-index: 2;
    padding: 70px 0 0;
  }
  .respond__description-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0;
    flex: auto;
    overflow: auto;
    max-width: 920px;
    margin: 0 auto;
    background-color: #ffffff;
  }
  .add-cover {
    background-color: rgba(0, 0, 0, 0.55);
    position: fixed;
    inset: 0;
    z-index: 4;
  }
  .how {
    background: #cef0ff;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    padding: 13px;
    margin: 16px auto 20px;
    gap: 8px;
    width: 100%;
    position: relative;
    text-align: left;
  }
  .how::before {
    content: "";
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-image: url(/assets/icons/success.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .how::after {
    content: "";
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-image: url(/assets/icons/menu-arr-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 13px;
  }
  .hide-on {
    display: none !important;
  }
  .field-flex {
    flex-direction: column;
  }
  .input-upload {
    gap: 20px;
  }
  .upload-data {
    padding-left: 0;
  }
  .upload-data::before {
    display: none;
  }
  .upload__info {
    display: none;
  }
  .preview-flex,
  .preview-flex-cont {
    flex-direction: column;
    gap: 16px;
  }
  .view__details {
    order: 1;
  }
  .view__media,
  .view__controls-inner {
    align-self: center;
    width: 100%;
    max-width: 411px;
  }
  .upload-controls {
    height: 67px;
    padding: 5px 15px;
    margin-top: 20px;
    position: relative;
    z-index: 0;
  }
  .upload-controls::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 300%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    border-top: 1px solid #dfdfdf;
    z-index: -1;
  }
  .view-chat__flex-item {
    flex: 1;
  }
  .pad--20 {
    padding: 0 20px;
  }
  .respond-title {
    font-size: 24px;
  }
  .respond__form {
    padding: 15px;
  }
  .hide_me {
    display: none !important;
  }
}
@media screen and (max-width: 920px) {
  .view__media {
    flex: auto;
  }
  .fiels-flex__item {
    width: 100%;
    flex: auto;
  }
  .fiels-flex__item.flex--small {
    flex: auto;
  }
  .gide-list {
    font-size: 14px;
  }
}
@media screen and (max-width: 539px) {
  .view--add {
    padding-top: 80px;
  }
  .slide-flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .view-price {
    padding: 4px 0 18px;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    gap: 8px;
  }
  .view-price::before {
    content: "";
    display: block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-image: url(/assets/icons/money-stack.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}