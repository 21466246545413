.cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 0%, 0.55);
}

.side-cover {
  position: fixed;
  top: 0;
  left: auto;
  right: -110%;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: hsla(0, 0%, 0%, 0.55);
  transition: 0.5s;
}
.side-cover.show--side {
  left: 0;
  right: 0;
}

.sidebar {
  display: none;
  flex-direction: column;
  width: 100vw;
  max-width: 735px;
  background: #f8f8f8;
  box-shadow: 15px 15px 20px 10px rgb(150, 150, 150);
  border-radius: 10px;
  padding: 20px 0;
  position: absolute;
  top: 40px;
  left: -10px;
  z-index: 5;
}
.sidebar.is--visible {
  display: flex;
}
.sidebar.is--visible + .sidebar-cover {
  display: block;
  position: fixed;
  inset: 0;
}
.sidebar.sidebar--column {
  max-width: 375px;
  height: calc(95vh - 40px);
  top: 0;
  margin-top: 40px;
}

.sidebar-cover {
  display: none;
}

.side-menu {
  width: 100%;
  max-height: 100%;
  padding: 0 20px;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
.side-menu.side-menu--column {
  grid-template-columns: repeat(1, 1fr);
}
.side-menu .site-item {
  width: 100%;
  height: 125px;
  padding: 0;
}
.side-menu .site-item__content {
  padding: 16px 20px;
}
.side-menu .site-item__title {
  font-size: 20px;
  line-height: 24px;
}
.side-menu .site-item__more {
  font-size: 11px;
}

.side-hider {
  position: absolute;
  top: 20px;
  right: -40px;
  height: 0;
}
.side-hider span {
  display: none;
}

.pop-up {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  background-color: #f8f8f8;
  overflow: hidden;
  padding: 30px;
  overflow: auto;
  border-radius: 10px;
  position: relative;
}
.pop-up.popup--435 {
  max-width: 435px;
}
.pop-up.popup--750 {
  max-width: 750px;
}
.pop-up.popup--600 {
  max-width: 600px;
}

.sideup-cover {
  position: fixed;
  inset: 0;
  z-index: 6;
  display: none;
}

.sideup {
  display: none;
  flex-direction: column;
  width: 100vw;
  max-width: 280px;
  max-height: 100vh;
  padding-top: 14px;
  overflow: auto;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 101%;
  background-color: #ffffff;
  box-shadow: 0px 12px 41px rgba(117, 117, 117, 0.25);
  z-index: 7;
}
.sideup.is--visible {
  display: flex;
}
.sideup.is--visible + .sideup-cover {
  display: block;
}

.sideup-header {
  padding: 0 20px;
}

.side-search {
  padding: 10px 20px;
}

.sideup-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.city-list {
  display: list-item;
  overflow: auto;
  height: 300px;
  margin-bottom: 14px;
}

.city__item {
  padding: 10px 20px;
  position: relative;
  width: 100%;
  text-align: left;
  overflow: hidden;
}
.city__item:not(:last-child)::after {
  content: "";
  width: 300%;
  height: 1px;
  background-color: #dfdfdf;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.check-list {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 230px;
  overflow: auto;
}

.check-list__item {
  width: 100%;
  flex: 0 0 20px;
  display: flex;
  align-items: center;
}

.sideup-controls {
  padding: 8px 20px;
  box-shadow: 0px -1px 16px rgba(158, 158, 158, 0.25);
}

.killer {
  width: 44px;
  height: 44px;
  background-image: url(/assets/icons/close.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.popup-header {
  padding-bottom: 22px;
}

.popup-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.popup-controls {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.my-contacts {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.contact-item {
  flex: 1;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.price-info {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/icons/info-grey.svg);
  background-size: contain;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .my-contacts {
    flex-direction: column;
  }
  .contact-item {
    flex: auto;
    width: 100%;
    margin-bottom: 0;
  }
}
.feedback__item {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #dfdfdf;
}

.feedback-title__box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 9px;
  flex-wrap: wrap;
}
.feedback-title__box .rate-box {
  width: auto;
  height: auto;
}

.rate-company__name {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #2499cb;
}

.feedback__item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px 27px;
  flex-wrap: wrap;
}

.feedback__inner-title {
  display: flex;
  align-items: center;
  gap: 0 15px;
  font-weight: 400;
  font-size: 12px;
}

.feedback-user {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
}

.feedback__auction {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 11px 0;
}
.feedback__auction span {
  color: #2499cb;
  padding-left: 4px;
}

.feedback__text {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}

.contact-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.contact__item {
  display: flex;
  align-items: center;
  gap: 0 20px;
}

.contact__item-title {
  flex: 0 0 80px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #4e4e4e;
}

.contact__item-data {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  gap: 0 10px;
}

.phone-wrap {
  white-space: nowrap;
}

.messengers {
  display: flex;
  align-items: center;
  gap: 0 4px;
}

.contact__item-web {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #2499cb;
}

.new-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating--popup {
  margin: 32px 0 40px;
}

.rate-it {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 40px;
  gap: 10px;
}

.rate-it__item {
  flex: 0 0 35px;
  height: 35px;
  background-image: url(/assets/icons/rate-no.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  transition: 0.25s;
}
.rate-it__item:hover {
  background-image: url(/assets/icons/rate-yes.svg);
}
.rate-it__item.marked {
  background-image: url(/assets/icons/rate-yes.svg);
}

@media screen and (max-width: 1180px) {
  .sidebar {
    border-radius: 0;
    max-width: 375px;
    padding: 0 0 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    margin-top: 0;
  }
  .sidebar.sidebar--column {
    margin-top: 0;
    height: 100vh;
  }
  .side-hider {
    position: static;
    width: auto;
    height: 50px;
    min-height: 50px;
    background-image: none;
    display: flex;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-left: 20px;
    background: #ffffff;
    border-bottom: 0.5px solid #dfdfdf;
    margin-bottom: 14px;
  }
  .side-hider::before {
    content: "";
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-image: url(/assets/icons/arrow-left-s-line.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
  }
  .side-hider span {
    display: inline-block;
  }
  .side-menu {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 16px;
  }
  .list--order {
    order: -1;
  }
  .sideup {
    position: fixed;
    inset: 0;
    max-width: unset;
    justify-content: space-between;
    z-index: 7;
    border-radius: 0;
  }
  .sideup-header {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .sideup-header .killer {
    left: 20px;
    width: 100%;
    height: 100%;
    background-position: center left;
    background-image: url(/assets/icons/arrow-left-s-line.svg);
  }
  .sideup-header .sideup-title {
    padding-left: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-left: 30px;
  }
  .side-form__flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .check-list {
    flex: auto;
  }
  .sideup-body {
    flex: auto;
  }
  .city-list {
    height: auto;
    max-height: 80vh;
  }
  .contact__item-data,
  .contact__item-web {
    font-size: 18px;
  }
}
@media screen and (max-width: 539px) {
  .sidebar {
    max-width: unset;
  }
}