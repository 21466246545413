.popup-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}
@media screen and (min-width: 1181px) {
  .popup-cover.desctop--modyfier {
    position: static;
    background-color: transparent;
  }
}

.popy {
  width: 100%;
  overflow: hidden;
  padding: 30px;
  overflow: auto;
  border-radius: 10px;
  position: relative;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}
.popy.is--visible {
  display: flex;
}
.popy.is--visible + .popy-cover {
  display: block;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 6;
}

.popy-video {
  max-width: 1007px;
  background-color: #f8f8f8;
}

.popy-foto {
  max-width: 1070px;
  background-color: transparent;
}
@media screen and (min-width: 1180px) {
  .popy-foto .popy-killer {
    right: 90px;
    background-size: 30px;
    background-image: url(/assets/icons/close-w.svg);
  }
}

.foto-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.foto__btn {
  flex: 0 0 45px;
}

.foto-btn__l,
.foto-btn__r {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  transition: 0.25s;
  cursor: pointer;
}
.foto-btn__l:hover,
.foto-btn__r:hover {
  transform: scale(0.95);
}

.foto-btn__l {
  background-image: url(/assets/icons/slide-l.svg);
}

.foto-btn__r {
  background-image: url(/assets/icons/slide-r.svg);
}

.foto-container {
  flex: auto;
  position: relative;
  margin: 0 auto;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4117647059);
  backdrop-filter: blur(5px);
}
.foto-container::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 68%;
}
.foto-container img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.video-container {
  width: 100%;
  position: relative;
  margin: 0 auto;
  align-self: center;
}
.video-container::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 60%;
}

.popy-title--hod {
  display: none;
}

.video-item {
  position: absolute;
  inset: 0;
}

.popy-more {
  max-width: 600px;
  background-color: #f8f8f8;
}

.popy-company {
  max-width: 880px;
  background-color: #f8f8f8;
  padding: 0;
}
.popy-company .popy-header {
  display: none;
}
.popy-company .popy-main {
  padding: 0;
}

.popy-agreement {
  max-width: 500px;
  background-color: #ffffff;
}

.popy-preview {
  max-width: 1110px;
}

.popy-data {
  max-width: 750px;
  background-color: #f8f8f8;
}

.new {
  max-width: 1180px;
  background-color: #f8f8f8;
}

.popy-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.popy-killer {
  width: 44px;
  height: 44px;
  background-image: url(/assets/icons/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (min-width: 1181px) {
  .popy-killer.desctop--modyfier {
    display: none;
  }
}

.popy-footer {
  text-align: center;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.main-button {
  font-family: "Rubik", sans-serif;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  padding: 0 20px;
  border-radius: 10px;
}
.main-button.is--grey {
  color: #4e4e4e;
  border: 1px solid #dfdfdf;
}
.main-button.is--blue {
  background-color: #2499cb;
  border: 1px solid #2499cb;
  color: #ffffff;
}
.main-button.is--red {
  color: #eb5757;
  border: 1px solid #eb5757;
  background-color: #ffffff;
}

.popy-main {
  padding-top: 22px;
  overflow: auto;
}

.services {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.service-item {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  padding: 22px;
  color: #4e4e4e;
}

.service-item__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-align: right;
  color: #4e4e4e;
  padding-right: 40px;
  position: relative;
  margin-bottom: 11px;
}

.inf__box {
  display: flex;
  align-items: center;
  gap: 12px;
}

.service-fee {
  font-weight: 500;
  color: #2499cb;
}

.service-info {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/icons/info-grey.svg);
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.service-item__text {
  font-size: 12px;
  font-weight: 500;
}

.agreement__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 10px;
}

.agreement-downloads {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.agreement-file {
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.agreement-file::before {
  content: "";
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/document-b.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.agreement-box {
  margin: 5px 0 28px;
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 15px 0;
}

.agreement-data {
  height: 250px;
  overflow: auto;
  padding: 0 20px;
}

.agreement-data__item {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  font-family: "PT Root UI", sans-serif;
  color: #4e4e4e;
  margin-bottom: 15px;
}

.popy-agree {
  display: flex;
  justify-content: center;
}
.popy-agree .checkbox-e + label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  font-family: "PT Root UI", sans-serif;
}

.close-note {
  display: none;
}

.photo-counter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  line-height: 26px;
  color: #a4a4a4;
  gap: 6px;
}
.photo-counter .boldy {
  color: #000000;
  font-weight: 500;
}

.photo-box {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.photo-control {
  flex: 0 0 85px;
}

.slider {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.slide__left {
  background-image: url(/assets/icons/slide-l.svg);
}

.slide__right {
  background-image: url(/assets/icons/slide-r.svg);
}

.photo-container {
  flex: auto;
  position: relative;
}
.photo-container::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 80%;
}

.photo-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.photo-item img {
  max-width: 100%;
  max-height: 100%;
}

.notifications {
  width: 100vw;
  max-width: 600px;
  max-height: 800px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.15);
  padding: 24px 0;
  position: absolute;
  top: 40px;
  right: 73%;
  display: none;
  z-index: 5;
}
.notifications.is--visible {
  display: flex;
}
.notifications.is--visible + .notifications-cover {
  display: block;
  position: fixed;
  inset: 0;
}

.notification__title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  padding: 0 30px;
  margin-bottom: 15px;
}

.is--note {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.is--note::after {
  content: "";
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/warning.svg);
  background-repeat: no-repeat;
}

.notifications__inner {
  flex: auto;
  overflow: auto;
  border-top: 1px solid #dfdfdf;
}

.notification__item {
  padding: 23px 30px;
  border-bottom: 1px solid #dfdfdf;
  font-family: "PT Root UI", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.notification__item-title {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
  font-family: "Rubik", sans-serif;
}

.notification__image-box {
  background-color: #e7e7e7;
}

.notification__btn-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.notification__dicument-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  height: 34px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  position: relative;
  background-color: #cef0ff;
  align-self: flex-start;
}

.notification__item-date {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4e4e4e;
}

.notification__item-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4e4e4e;
}

.user-drop {
  width: 100vw;
  max-width: 400px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.15);
  text-align: center;
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 5;
  display: none;
}
.user-drop + .user-cover {
  display: none;
}
.user-drop.is--visible {
  display: block;
}
.user-drop.is--visible + .user-cover {
  display: block;
  position: fixed;
  inset: 0;
}

.user-drop__data {
  height: 80px;
  background-color: #cef0ff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 14px;
  font-weight: 500;
  font-size: 17px;
  color: #000000;
}

.user-drop__avatar-box {
  width: 55px;
  min-width: 55px;
  height: 55px;
  position: relative;
}
.user-drop__avatar-box img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.user-drop__avatar-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: url(/assets/icons/pen-solid-white.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  position: absolute;
  right: -3px;
  bottom: -3px;
}

.user-drop__menu {
  padding: 23px 20px;
  border-bottom: 1px solid #dfdfdf;
}

.user-drop__menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4e4e4e;
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 15px;
}
.user-drop__menu-item::before {
  content: "";
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.user-drop__menu-item + .user-drop__menu-item {
  margin-top: 16px;
}
.user-drop__menu-item.cabinet::before {
  background-image: url(/assets/icons/darhboard.svg);
}
.user-drop__menu-item.edit::before {
  background-image: url(/assets/icons/Edit-data.svg);
}
.user-drop__menu-item.help::before {
  background-image: url(/assets/icons/help.svg);
}
.user-drop__menu-item.exit::before {
  background-image: url(/assets/icons/exit-to-app.svg);
}

.user {
  width: 100vw;
  max-width: 375px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.15);
  text-align: center;
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 5;
  display: none;
}
.user + .user-cover {
  display: none;
}
.user.is--visible {
  display: block;
}
.user.is--visible + .user-cover {
  display: block;
  position: fixed;
  inset: 0;
}

.user__inner {
  padding: 50px 20px 20px;
  position: relative;
  background-color: #ffffff;
  z-index: 99;
}
.user__inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  background-color: #cff0ff;
  z-index: -1;
}

.user__close {
  width: 40px;
  height: 40px;
  background-image: url(/assets/icons/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.user__name {
  font-size: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #4e4e4e;
  z-index: 99;
}

.avatar__cont {
  position: relative;
  display: inline-block;
  position: relative;
  margin: 20px auto;
}

.user__img-container {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border: solid 3px #ffffff;
}

.user-img {
  height: 100%;
  width: auto;
  display: block;
}

.btn__img {
  position: absolute;
  top: 15px;
  right: -30px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 2px #4e4e4e;
  transition: 0.25s;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  background-image: url(/assets/icons/pen-solid.svg);
  opacity: 0.5;
}

.user__mail {
  color: #979797;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.user__ctrl {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.user__ctrl-btn {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px #4e4e4e;
  transition: 0.25s;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;
}

.btn--location {
  background-image: url(/assets/icons/location-dot-solid.svg);
}

.btn--edit {
  background-image: url(/assets/icons/pen-solid.svg);
}

.btn--pass {
  background-image: url(/assets/icons/key-solid.svg);
}

.user__logout {
  background-color: #cff0ff;
  padding: 10px 20px;
  position: relative;
  z-index: 99;
}

.user__txt {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
}

.icon__txt {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}
.icon__txt::before {
  content: "";
  display: block;
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.synk--txt::before {
  background-image: url(/assets/icons/rotate-solid.svg);
}

.edit--txt::before {
  background-image: url(/assets/icons/key-solid.svg);
}

.out--txt::before {
  background-image: url(/assets/icons/out.svg);
}

.split-line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #979797;
  margin-bottom: 10px;
}

.out {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/icons/out.svg);
  background-size: 80%;
  margin-right: 8px;
}

@media screen and (max-width: 1179px) {
  .popup-cover {
    align-items: flex-start;
  }
  .popy {
    border-radius: 0;
    padding: 0;
    max-width: 375px;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translate(-50%, 0%);
    top: 0;
  }
  .popy.popy-data {
    max-width: 750px;
  }
  .popy.new {
    max-width: 920px;
  }
  .popy.popy-video {
    max-width: 1007px;
  }
  .popy.popy-foto {
    max-width: 1007px;
  }
  .popy.popy-company {
    max-width: 880px;
    height: auto;
    min-height: unset;
    display: block;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .popy.popy-company .popy-header {
    display: flex;
  }
  .popy-title--hod {
    display: block;
  }
  .popy-header {
    flex: 0 0 50px;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #ffffff;
    border-bottom: 1px solid #dfdfdf;
    padding: 0 15px;
    position: relative;
  }
  .popy-killer {
    width: 100%;
    min-width: 100%;
    height: 100%;
    background-image: url(/assets/icons/arrow-left-s-line.svg);
    background-position: 15px center;
    left: 0;
  }
  .popy-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-left: 30px;
  }
  .popy-main {
    flex: auto;
    padding: 15px;
    display: flex;
  }
  .popy-main form {
    width: 100%;
  }
  .popy-main.full--size {
    padding: 0;
    background-color: #ffffff;
  }
  .popy-footer {
    flex: 0 0 67px;
    height: 67px;
    border-top: 1px solid #dfdfdf;
    padding: 5px 15px;
    background-color: #ffffff;
  }
  .to--dark {
    background-color: #f8f8f8;
  }
  .agreement-file {
    position: absolute;
    top: 5px;
    right: 20px;
  }
  .agreement-box {
    background-color: #f8f8f8;
    margin-bottom: 0;
    padding: 0;
  }
  .agreement-data {
    height: auto;
    padding: 0;
  }
  .popy-agree {
    justify-content: flex-start;
  }
  .close-note {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4e4e4e;
    z-index: 0;
  }
  .hide-on__photo {
    opacity: 0;
    z-index: 1;
  }
  .photo-control {
    display: none;
  }
  .photo-container::before {
    padding-top: 88.5%;
  }
  .photo-item {
    border-radius: 0;
  }
  .notifications.is--visible {
    position: fixed;
    border-radius: 0;
    inset: 0;
    max-width: unset;
    max-height: unset;
    z-index: 2;
  }
  .foto-container {
    min-width: 100%;
  }
  .foto__btn {
    display: none;
  }
}
@media screen and (max-width: 539px) {
  .user-drop {
    max-width: unset;
    position: fixed;
    inset: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .user {
    max-width: unset;
    position: fixed;
    inset: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .user.is--visible {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (orientation: landscape) and (max-width: 1024px) {
  .popy-video .popy-header,
  .popy-foto .popy-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    opacity: 0.5;
  }
  .popy-video .popy-main,
  .popy-foto .popy-main {
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .foto-container {
    flex: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
  }
  .video-container {
    width: 100%;
    height: 100%;
  }
  .video-container::before {
    display: none;
  }
  .video-item {
    position: static;
    height: 100%;
  }
}