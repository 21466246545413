.help__inner {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 30px;
}

.help__inner-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.help__item {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  padding: 23px 30px;
}

.help__item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.help__item__subtitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #A4A4A4;
  margin-top: 12px;
}

.support-list {
  padding-top: 10px;
}

.support__item {
  padding: 4px 0;
}
.support__item a {
  font-weight: 500;
  color: #2499cb;
}

.faq__item {
  height: auto;
  max-height: 50px;
  overflow: hidden;
  transition: 0.25s;
  border-bottom: solid 1px #DFDFDF;
}
.faq__item.active {
  max-height: unset;
}

.faq__title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}

.faq__arrow {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/arr-down.svg);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}

.faq__item-description {
  padding: 10px;
}

.whats-new {
  padding-top: 20px;
}

.whats-new__item {
  padding-bottom: 15px;
  padding-left: 20px;
  position: relative;
}
.whats-new__item:not(:last-child)::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #DFDFDF;
  position: absolute;
  top: 10px;
  left: 0;
}
.whats-new__item::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #2499CB;
  position: absolute;
  top: 10px;
  left: -3px;
}

.whats-new__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}

.whats-new__desc {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  padding: 6px 0 9px;
}

.whats-new__time {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A4A4A4;
}

.whats-new__sublist {
  list-style: disc;
  padding: 10px 0 10px 20px;
}

@media screen and (max-width: 1200px) {
  .help__inner {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .help__item {
    padding: 16px;
  }
}