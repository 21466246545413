@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url(./elements.css);
@import url(./footer.css);
@import url(./header.css);
@import url(./new-add.css);
@import url(./popup.css);
@import url(./bid.css);
@import url(./nullstyles.css);
@import url(./swiper.css);
@import url(./add-list.css);
@import url(./sign.css);
@import url(./chat.css);
@import url(./help.css);
@import url(./popup-new.css);
@import url(./politic.css);
@import url("https://fonts.cdnfonts.com/css/pt-root-ui");
html ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
html ::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
html ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
html ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
html ::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

body {
  font-family: "Rubik", sans-serif;
}

.body-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f8f8f8;
}

.v--hidden {
  display: none;
  position: absolute;
}

*::placeholder {
  color: #999;
}

*::placeholder {
  font-size: 16px;
}

*::placeholder {
  font-family: inherit;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  z-index: 5;
}

.ws--pre {
  white-space: pre-line;
}

.ptr {
  cursor: pointer;
  transition: 0.25s;
}

.mb--30 {
  margin-bottom: 30px;
}

.mt--30 {
  margin-top: 30px;
}

.w--100 {
  width: 100%;
}

.blue--txt {
  color: #2499cb;
}

.main {
  flex: auto;
  padding: 100px 0 30px;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  color: #4e4e4e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
}

.mb--40 {
  margin-bottom: 40px;
}

.mb--20 {
  margin-bottom: 20px;
}

.section-title {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
}

.home {
  text-align: center;
}

.home-title {
  font-weight: 600;
  font-size: 60px;
  line-height: 71px;
  padding: 20px 0;
}

.home-subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.temp-header {
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: #ff6d35;
  padding: 0px 0 10px;
}

.site-sections {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  padding: 80px 0 100px;
}

.site-item {
  width: 33.333%;
  max-width: 510px;
  height: 282px;
  padding: 10px;
  text-align: left;
}

.single-item {
  width: 345px;
  height: 125px;
}
.single-item .site-item {
  width: 100%;
  height: 100%;
  padding: 0;
}
.single-item .site-item__content {
  padding: 16px 20px;
}
.single-item .site-item__title {
  font-size: 20px;
  line-height: 24px;
}
.single-item .site-item__more {
  font-size: 11px;
}

.site-item__inner {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.site-item__img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 0;
}

.site-item__content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.site-item__title {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.site-item__more {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 103%;
  font-style: italic;
}
.site-item__more::before {
  content: "";
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/youtube.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}

.site-item__desc {
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4e4e4e;
}

.bottom-banner-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #4e4e4e;
  text-align: center;
}

.bottom-banner-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 14px;
  padding: 24px 0;
}

.bottom-banner-item {
  flex: 0 0 374px;
  height: 150px;
}

.bb-img {
  width: 100%;
  height: 100%;
}

.main-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}

.main-flex__item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow: hidden;
}

.text-regular {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4e4e4e;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.card-title__counter {
  color: #a4a4a4;
}

.card-title__icon {
  width: 32px;
  margin-right: 4px;
}

.fill-up {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 115.5%;
  color: #4e4e4e;
}

.fill-up__box {
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ea9210;
  border: 2.5px solid #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-filter {
  padding-top: 15px;
}

.swiper-checkbox {
  display: inline-flex;
  padding: 2px;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
}
.swiper-checkbox input {
  display: none;
  opacity: 0;
  position: absolute;
  left: -2000px;
  visibility: hidden;
}
.swiper-checkbox input:checked + label .actual {
  background-color: transparent;
  color: #4e4e4e;
}
.swiper-checkbox input:checked + label .not__actual {
  background-color: #cef0ff;
  color: #000000;
}
.swiper-checkbox label {
  display: flex;
  align-items: center;
  transition: 0.25s;
  cursor: pointer;
}
.swiper-checkbox label span {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding: 0 15px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: 0.25s;
}
.swiper-checkbox label .actual {
  background-color: #cef0ff;
  color: #000000;
}
.swiper-checkbox label .not__actual {
  color: #4e4e4e;
}

.card-body {
  padding-top: 24px;
}

.card__text-item {
  padding: 12px 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4e4e4e;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.card__text-item:not(:last-child) {
  border-bottom: 1px solid #dfdfdf;
}

.card-data-box {
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  cursor: pointer;
}

.card-item__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.card-item__title {
  transition: 0.2s;
}
a.card-item__title:hover {
  color: #ea9210;
}

.blind--text {
  color: #a4a4a4;
}

.striked {
  text-decoration: line-through;
  font-size: 12px;
  color: #a4a4a4;
}

.card-item__desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-item__details {
  font-family: "PT Root UI", sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
}

.card-rank-box {
  flex: 0 0 0px;
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4e4e4e;
  white-space: nowrap;
}
.card-rank-box span {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ef950d;
}

.data-controls-box {
  display: flex;
  gap: 8px;
}

.rest-items {
  width: 100%;
  height: 16px;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 16px;
  background-image: url(/assets/icons/show-more.svg);
  background-repeat: no-repeat;
  background-position: center right;
  color: #a4a4a4;
  cursor: pointer;
  margin-top: 23px;
}

.pushup-banner {
  background: #cef0ff;
  border-radius: 10px;
  height: 74px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
}

.banner-img {
  height: 100%;
}

.banner-txt {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

.avatar-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.location {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 15px;
  padding: 12px 0;
  padding-left: 28px;
  position: relative;
  color: #2499cb;
}
.location::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  flex: 0 0 22px;
  background-image: url(/assets/icons/map.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 10px;
}

.media-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 16px;
}

.media__item {
  flex: 0 0 25%;
  min-width: 80px;
  border: solid 1px transparent;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.media__img {
  z-index: 1;
}

.data-documentation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4e4e4e;
  padding: 20px 0;
}

.text--green {
  color: #3fbe62;
}

.pdf-link {
  display: flex;
  align-items: center;
  gap: 8px;
}
.pdf-link::before {
  content: "";
  display: block;
  width: 20px;
  flex: 0 0 20px;
  height: 20px;
  background-image: url(/assets/icons/pdf.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.catalog-link {
  display: flex;
  align-items: center;
  gap: 8px;
}
.catalog-link::before {
  content: "";
  display: block;
  width: 20px;
  flex: 0 0 20px;
  height: 20px;
  background-image: url(/assets/icons/Website.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.chair {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
}
.chair::before {
  content: "";
  display: block;
  width: 20px;
  flex: 0 0 20px;
  height: 20px;
  background-image: url(/assets/icons/chair.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.my-links {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.add-list__item {
  border-bottom: 1px solid #dfdfdf;
}

.add-item {
  display: flex;
  align-items: stretch;
  padding: 2px;
  border-radius: 10px;
}
.add-item:hover {
  background-color: rgba(153, 153, 153, 0.1529411765);
}

.add-item__image-box {
  width: 115px;
  flex: 0 0 115px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-item__image {
  height: 100%;
  width: auto;
}

.add-item__content-box {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
}

.add-item__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  transition: 0.25s;
}
.add-item__title:hover {
  color: #ea9210;
}

.add-item__details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.add-price__box {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.add__price {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #ea9210;
}

.banner-section-2 {
  width: 100%;
}

.banner-img-2 {
  width: 100%;
  height: auto;
}

.media__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  height: 100%;
}
.media__controls .delete-btn-white {
  opacity: 0;
}

.favy {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.favy + label {
  display: block;
  width: 30px;
  height: 30px;
  background-image: url(/assets/icons/Star-e.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  cursor: pointer;
  opacity: 0;
}
.favy:checked + label {
  background-image: url(/assets/icons/Star-w.svg);
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: 0.25s;
}

.media__item:hover .media__controls {
  background-color: rgba(80, 80, 80, 0.4196078431);
}
.media__item:hover .favy + label {
  opacity: 1;
  transition: 0.25s;
}
.media__item:hover .delete-btn-white {
  opacity: 1;
}

.benefits-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  list-style: disc;
  padding-left: 16px;
  padding-top: 6px;
}

.benefits__period {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 15px;
  margin-bottom: 28px;
  font-style: italic;
  color: #4e4e4e;
  flex-wrap: wrap;
}
.benefits__period span {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 4px;
}
.benefits__period img {
  width: 24px;
  height: 24px;
}

.pkg-flex {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.pkg__item {
  flex: 0 0 30%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 140px;
  border-radius: 10px;
  border: solid 2px #dfdfdf;
  transition: 0.25s;
  background-color: #ffffff;
}
.pkg__item:hover {
  border: 2px solid #ea9210;
}

.pkg__discount {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #ea9210;
  position: absolute;
  top: 7px;
  right: 10px;
}

.pkg__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.pkg__price {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #2499cb;
}

.pkg__striked {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: line-through;
  color: #4e4e4e;
}

.checkbox-set {
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding-bottom: 20px;
}

.checkbox-set__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4e4e4e;
}

.checkbox-list {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.cabinet-banner {
  margin-top: 30px;
  position: relative;
}

.cabinet-banner__image {
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
}

.cabinet-banner__slogan {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 600;
  font-size: 40px;
  transform: translate(-50%, -50%) rotate(-16.87deg);
  line-height: 1.2;
  color: #2499cb;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}

@media screen and (max-width: 1439px) {
  .pkg__item {
    padding: 20px 8px;
  }
  .site-item__content {
    padding: 16px 20px;
  }
  .site-item__title {
    font-size: 26px;
    line-height: 28px;
  }
}
@media screen and (max-width: 1023px) {
  .card-title {
    font-size: 24px;
  }
  .home-title {
    font-size: 30px;
    line-height: 36px;
    padding: 9px 0;
    max-width: 350px;
    margin: 0 auto;
  }
  .home-subtitle {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
  }
  .temp-header {
    font-size: 18px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    padding: 0px 0 10px;
  }
  .site-sections {
    padding: 18px 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .site-item {
    width: 100%;
    flex: 0 0 131px;
    height: 131px;
    padding: 3px 0;
  }
  .site-item__title {
    font-size: 20px;
    line-height: 24px;
  }
  .site-item__more {
    font-size: 11px;
  }
  .bottom-banner-title {
    font-size: 16px;
    line-height: 19px;
  }
}
@media screen and (max-width: 919px) {
  .main-flex {
    flex-direction: column;
    width: 100%;
  }
  .main-flex__item {
    flex: auto;
    width: 100%;
  }
  .section-title {
    font-size: 26px;
    line-height: 31px;
  }
}
@media screen and (max-width: 639px) {
  .container.small--container {
    padding: 2px;
  }
  .main-flex__item {
    gap: 9px;
  }
  .card-title {
    font-size: 20px;
    line-height: 24px;
  }
  .card-filter {
    padding-top: 7px;
  }
  .swiper-checkbox label span {
    height: 22px;
    line-height: 14px;
    border-radius: 5px;
  }
  .card-body {
    padding-top: 16px;
  }
  .card-item__title {
    font-size: 14px;
    line-height: 17px;
  }
  .card-item__details {
    font-size: 12px;
  }
  /*.card-rank-box {
    display: none;
  }*/
  .add-item__image-box {
    width: 100px;
    flex: 0 0 100px;
    height: 85px;
  }
  .add-item__title {
    font-size: 14px;
    line-height: 17px;
  }
  .add__price {
    font-size: 13px;
    line-height: 15px;
  }
  .pkg__item {
    padding: 20px 0px;
    align-items: center;
  }
  .pkg__item .button-27e {
    padding: 4px;
  }
  .pkg__price-box {
    padding: 0 8px;
  }
  .location {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .location::before {
    top: 50%;
    transform: translateY(-50%);
  }
  .loc-street {
    font-weight: 500;
    font-size: 12px;
  }
  .site-sections {
    flex-direction: column;
    margin: 0;
  }
  .site-item {
    width: 100%;
    flex: 0 0 131px;
    height: 131px;
    padding: 3px 0;
  }
  .site-item__content {
    padding: 16px 20px;
  }
  .bottom-banner-item {
    width: 100%;
    max-width: 375px;
    flex: auto;
  }
  .media__item {
    flex: 0 0 25%;
  }
  .rest-items {
    margin-top: 12px;
  }
  .cabinet-banner__slogan {
    font-size: 30px;
  }
}
.h-section {
  padding-top: 50px;
}

.h-title {
  font-weight: 600;
  font-size: 60px;
  line-height: 71px;
  color: #000000;
  text-align: center;
  padding-bottom: 20px;
}

.h-subtitle {
  width: 100%;
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  gap: 0 6px;
}

.h-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 54px 0 89px;
}

.h-item {
  flex: 0 0 380px;
  height: 282px;
  position: relative;
  padding: 24px 0 28px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 0;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
}

.h__img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: auto;
  z-index: -1;
}
.h__img.img--88 {
  height: 88%;
  bottom: auto;
  top: 0;
}
.h__img.img--96 {
  height: 96%;
  bottom: auto;
  top: 0;
}

.h-item__title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.h-details {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 103%;
  color: #4e4e4e;
  font-style: italic;
  opacity: 0.7;
}
.h-details::before {
  content: "";
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/youtube.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}

.h-banner-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #4e4e4e;
  text-align: center;
}

.h-banner-flex {
  padding: 23px 0 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.h-banner__item {
  flex: 0 0 374px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.h-banner__image {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.h-banner__slogan {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 600;
  font-size: 30px;
  transform: translate(-50%, -50%) rotate(-16.87deg);
  line-height: 1.2;
  color: #2499cb;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
}

.rating {
  width: 100%;
  max-width: 200px;
  position: relative;
}
.rating::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 21.1%;
}

.rating__inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-size: auto 100%;
  background-position: top left;
  background-repeat: no-repeat;
}

.rating__base {
  background-image: url(/assets/icons/c-stars.svg);
  width: 100%;
  z-index: 1;
}

.rating__low {
  background-image: url(/assets/icons/b-stars.svg);
  z-index: 2;
}

.rating__hight {
  background-image: url(/assets/icons/a-stars.svg);
  z-index: 3;
}

@media screen and (max-width: 1179px) {
  .main {
    padding-top: 80px;
  }
  .h-section {
    padding-top: 0;
    max-width: 345px;
    margin: 0 auto;
  }
  .h-title {
    font-size: 30px;
    line-height: 36px;
    max-width: 350px;
    margin: 0 auto;
    padding-bottom: 9px;
  }
  .h-subtitle {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .h-flex {
    flex-direction: column;
    justify-content: flex-start;
    padding: 18px 0 25px;
    gap: 5px;
  }
  .h-item {
    flex: auto;
    width: 100%;
    height: 125px;
    padding: 16px 0 16px 20px;
  }
  .h__img {
    height: 100%;
  }
  .h-item__title {
    font-size: 20px;
    line-height: 24px;
  }
  .h-details {
    font-size: 11px;
  }
  .h-banners {
    max-width: 345px;
    margin: 0 auto;
  }
  .h-banner-title {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
  .h-banner-flex {
    gap: 5px;
    padding: 13px 0 50px;
  }
  .h-banner__item {
    flex: 1;
    height: 89px;
  }
  .h-banner__slogan {
    font-size: 18px;
  }
}
.portal-welcome {
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
  top: 7px;
}

.f-title {
  font-family: "Rubik";
  font-weight: 600;
  font-size: 59px;
  line-height: 71px;
  color: #000000;
  text-align: center;
  margin-bottom: 37px;
}

.f-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px 26px;
  margin-bottom: 8px;
}

.f-section__item {
  height: 210px;
  padding: 24px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  overflow: hidden;
}

.f-section__item-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.f-section__item-title {
  max-width: 190px;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  z-index: 1;
}

.f-presentation {
  display: flex;
  align-items: center;
  gap: 5px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #4e4e4e;
}
.f-presentation span {
  opacity: 0.7;
  position: relative;
  top: -12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.f-presentation::before {
  content: "";
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/youtube.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.f-flag {
  height: 125px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  overflow: hidden;
  margin: 0 15px;
}

.f-flag__l {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 10px;
  overflow: hidden;
}

.f-flag__r {
  flex: 0 0 196px;
  display: flex;
  align-items: flex-end;
}

.f-flag__title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.f-welcome {
  display: flex;
  align-items: center;
  gap: 5px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #4e4e4e;
}
.f-welcome span {
  opacity: 0.7;
  position: relative;
  top: -12px;
}
.f-welcome::before {
  content: "";
  display: block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-image: url(/assets/icons/Website.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.f-flag__link {
  display: inline-block;
  width: 100%;
}

.f-flag__img {
  display: block;
  width: 100%;
  height: 125px;
}

.data-flex {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.data__main {
  flex: auto;
}

.data__form {
  padding-top: 13px;
}

.textarea--my-data {
  min-height: 90px;
}

.data-controls {
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 20px 0;
}
.data-controls .button-f {
  flex: auto;
}

.scan-fieldset {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 12px 20px;
}

.cadcam-fieldset {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 12px 20px;
  margin: 30px 0 0 0;
}

.label--split {
  justify-content: space-between;
}

.field--white {
  margin-top: 5px;
}
.field--white .input {
  background-color: #ffffff;
}

.field--white-cadcam {
  margin-top: 40px;
}
.field--white-cadcam .input {
  background-color: #ffffff;
}

.notification-section {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.notification-section .notifications__inner {
  margin-top: 20px;
}
.notification-section .notification__item {
  padding-left: 0;
  padding-right: 0;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notes-ctrls {
  display: flex;
  gap: 8px;
}
.notes-ctrls button {
  opacity: 0.7;
  transition: 0.25s;
}
.notes-ctrls button:hover {
  opacity: 1;
}

@media screen and (max-width: 1180px) {
  .m--1 {
    order: 0;
  }
  .m--2 {
    order: 1;
  }
  .m--3 {
    order: 2;
  }
  .m--4 {
    order: 3;
  }
  .m--5 {
    order: 4;
  }
  .m--6 {
    order: 5;
  }
  .m--7 {
    order: 6;
  }
  .m--8 {
    order: 7;
  }
  .f-title {
    font-size: 30px;
    line-height: 40px;
    max-width: 540px;
    margin: 0 auto 20px;
  }
  .f-grid {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
  }
  .f-flag {
    margin: 0;
  }
}
@media screen and (max-width: 539px) {
  .main.top--0 {
    padding-top: 0;
  }
}