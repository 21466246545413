.sign-cover {
  background-color: #F8F8F8;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sign-header {
  flex: 0 0 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-header__box {
  text-align: center;
}
.sign-header__box a {
  margin: 0 auto 7px;
}

.sign-header__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #4E4E4E;
}

.sign-main {
  flex: auto;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sign-main2 {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sign-body {
  padding: 16px 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  position: relative;
}
.sign-body.sign--cat {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.sign--confirm {
  width: 100%;
  max-width: 435px;
}

.user-type {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #2499CB;
}

.edit-container {
  width: 100%;
  margin-top: -30px;
  display: flex;
  justify-content: flex-end;
}

.sign--fin {
  width: 100%;
  max-width: 547px;
}

.sign--in {
  width: 100%;
  max-width: 435px;
}

.sign--808 {
  width: 100%;
  max-width: 808px;
}

.sign--cat {
  width: 100%;
  max-width: 661px;
}

.sign-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.sign-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sign-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 6px 0 20px 0;
}

.h--44 {
  font-size: 17px;
}

.forgot--button {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4E4E4E;
  margin-top: 12px;
}

.sing-up-container {
  width: 100%;
  max-width: 832px;
  min-height: 665px;
  display: flex;
  align-items: stretch;
  border-radius: 10px;
  overflow: hidden;
  background-color: #FFFFFF;
}

.sign-up__info {
  flex: 1 1 397px;
  background-image: url(/assets/images/stomat.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
}

.sign-up__info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sign-up__info-item {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}

.showon--mobile {
  display: none;
}

.sign-up__body {
  flex: 1 1 435px;
  display: flex;
  align-self: stretch;
}

.button-info {
  width: 18px;
  height: 18px;
  background-image: url(/assets/icons/info-grey.svg);
  background-size: contain;
  margin-left: 6px;
}

.choice-set {
  background: #F8F8F8;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  position: relative;
}
.choice-set .button-info {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
}

.checkbox + .sign-label {
  font-size: 12px;
  line-height: 15px;
}
.checkbox + .sign-label a {
  color: #2499CB;
  text-decoration: underline;
}

.blue--text {
  color: #2499CB;
}

.counter-box {
  padding-top: 16px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.timer {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #4E4E4E;
}

.timer-note {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A4A4A4;
}

.aparat-amount {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sign__sub-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 0 22px;
  color: #4E4E4E;
}

.avatar-upload {
  background: #F2F2F2;
  border-radius: 10px;
  padding: 6px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4E4E4E;
}

.avatar-upload__inner {
  display: flex;
  align-items: center;
  gap: 14px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.avatar-upload__image {
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-image: url(/assets/icons/upload-img.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.field-set__cover {
  background: #F2F2F2;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 150px;
  position: relative;
}
.field-set__cover .slide-flex label {
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.field-set__cover .input {
  background-color: #FFFFFF;
}

.w--335 {
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
}

.w--between {
  justify-content: space-between;
}

.final-img {
  display: block;
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.final-msg {
  width: 100%;
  max-width: 387px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 26px;
}
.final-msg.final-msg--pass {
  max-width: unset;
}

.sgn__sub {
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  margin: 7px 0 10px;
  color: #4E4E4E;
}

.final-msg__title {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}

.final-msg__txt {
  font-family: "PT Root UI", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  margin: 16px 0 48px;
}

.w--335 {
  width: 100%;
  max-width: 335px;
}

.cat-flex {
  display: flex;
  gap: 0 6px;
}

.cat-container {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  overflow: hidden;
  height: 427px;
  flex: 0 0 280px;
  padding: 25px 0px;
}

.cat__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding: 0 20px;
}

.cat__item-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 1px solid #DFDFDF;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4E4E4E;
  text-align: left;
  transition: 0.25s;
}
.cat__item-button::after {
  content: "";
  display: block;
  width: 8px;
  min-width: 8px;
  height: 14px;
  background-image: url(/assets/icons/menu-arr.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.cat__item-button:hover {
  background-color: #CEF0FF;
}
.cat__item-button:hover::after {
  background-image: url(/assets/icons/menu-arr-blue.svg);
}

.details-container {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
  border-radius: 10px;
  overflow: hidden;
  height: 427px;
  flex: auto;
  padding: 25px 30px;
}

.cat__details-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.cat__detail-item {
  padding: 2px;
  margin-bottom: 10px;
  width: 50%;
  padding-right: 4px;
}

.cat-controls {
  padding: 30px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w--250 {
  width: 100%;
  max-width: 250px;
}

@media screen and (max-width: 1179px) {
  .sign-header__title {
    font-size: 14px;
    line-height: 17px;
  }
  .changer {
    display: none;
  }
  .sign-body {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(209, 209, 209, 0.25);
    border-radius: 10px;
  }
  .sign-body.sign--cat {
    background: #FFFFFF;
    box-shadow: none;
    border-radius: 10px;
    max-width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .details-container,
  .cat-container {
    flex: auto;
    box-shadow: none;
  }
  .w--250 {
    max-width: unset;
  }
}
@media screen and (max-width: 839px) {
  .showon--mobile {
    display: block;
  }
  .sign-up__body {
    flex: auto;
    display: none;
  }
  .sign--up {
    width: 100%;
  }
}
@media screen and (max-width: 439px) {
  .sign-header {
    flex: 0 0 86px;
  }
  .sign-top-flex {
    display: flex;
    flex-direction: column;
  }
  .edit-container {
    margin-top: 0;
    justify-content: flex-start;
    order: 1;
    padding: 7px 0 13px;
  }
  .sign-main {
    padding: 0px;
  }
  .sign-body {
    padding: 8px 20px;
  }
  .sign-main {
    align-items: stretch;
  }
  .sign-up__info {
    padding: 44px 20px;
  }
}