.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.swiper-thumbs {
    padding-top: 8px;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
    cursor: pointer;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: auto;
    opacity: 0.4;
}

.swiper-horizontal {
    margin-top: 2px;
}

.swiper-slide-thumb-active {
    border: solid 2px #2499CB;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination {
    opacity: 0;
}

@media screen and (max-width: 767px) {
    .hideon--mobile {
        display: none !important;
    }

    .swiper-pagination {
        opacity: 1;
    }

    .view__media-inner {
        height: 300px;
    }

    .mySwiper {
        height: 0;
    }

    .mySwiper2 {
        height: 100%;
    }
}